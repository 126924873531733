<template lang="html">
  <section class="expositions">
    <hero-global img="/img/hero/actualidad.svg" title="Actualidad" subtitle="Actualidad" />
    <section class="container" typecontent="noticia">
      <section class="page-entry" v-if="entrySelected">
        <!-- <router-link to="/actualidad" class="btn-back" title="Volver a la página anterior">
          Volver
        </router-link> -->
        <div @click="goToBack()" class="btn-back" title="Volver a la página anterior">Volver</div>
        <div class="head-content">
          <div class="box-date" v-if="entrySelected.type == 'event'">
            <span class="number">{{ currentDateDay(entrySelected.start_date) }}</span>
            <span class="month"> {{ currentDateMonth(entrySelected.start_date) }}</span>
          </div>

          <div class="content-cont">
            <Markdown
              class="title-h1"
              role="»heading»"
              aria-level="»1″"
              :source="`<h1>${this.entrySelected.name_markdown}</h1>`"
              html="true"
              v-if="entrySelected.name_markdown"
            />
            <h1 class="title-h1" v-else>
              <strong>{{ entrySelected.name }}</strong>
            </h1>
            <div class="container-head-data">
              <div class="info-data">
                <p class="box-dates">
                  <span class="date-text m-right-10-px" v-if="entrySelected.type == 'event'">{{ currentDateTime(entrySelected.start_date) }}</span>
                  <template v-else>
                    <span class="date-text m-right-10-px">{{ currentDateTime(entrySelected.publish_date) }}</span>
                  </template>
                  <span class="author" v-if="entrySelected.writer_name"> {{ entrySelected.writer_name }}</span>

                  <template v-for="theme in entrySelected.themes" :key="theme.id">
                    <span class="category-tag">{{ theme.name }}</span>
                  </template>
                  <span class="category-location" v-if="entrySelected.zone">{{ entrySelected.zone.name }}</span>
                </p>
              </div>
              <div class="shared-header">
                <rrssShared :dataTitle="entrySelected.name"></rrssShared>
              </div>
            </div>
            <p class="subtitle" v-if="entrySelected.header">
              {{ entrySelected.header }}
            </p>
            <p class="subtitle" v-if="entrySelected.description">
              {{ entrySelected.description }}
            </p>
          </div>
        </div>
        <section :class="[entrySelected.type == 'event' ? 'newsletter-detail cont-evento' : 'newsletter-detail']">
          <!-- //HEAD entry OR HEAD EVENTS-->
          <div v-if="this.$route.params.typeContent == 'multimedia' && entrySelected.url">
            <div class="column-center">
              <iframe
                :src="verifyIframe(entrySelected.url)"
                width="640"
                height="360"
                frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>
          <template v-else>
            <div class="container-img -event" v-if="entrySelected.image.url">
              <img
                class="main-img"
                :src="entrySelected.image.url"
                :alt="entrySelected.image_footer || entrySelected.description"
                :title="entrySelected.image_footer"
              />
              <span v-if="entrySelected.image_footer">{{ entrySelected.image_footer }}</span>
            </div>
          </template>

          <template v-if="entrySelected.body">
            <EditorJs :object="entrySelected.body" />
          </template>

          <p>
            <strong v-if="entrySelected.interviewer">Por: </strong><span>{{ entrySelected.interviewer }}</span>
          </p>
          <p v-if="entrySelected.source" class="source-p">
            <strong>Fuente: </strong><span>{{ entrySelected.source }}</span>
          </p>
          <template v-if="this.$route.params.typeContent == 'novedad'">
            <div class="list-download container" v-for="(el, key) in entrySelected.file" :key="key">
              <a download :href="el.url" class="btn-blue-border" rel="noopener" target="_blank"> Descargar documento</a>
            </div>
          </template>
          <div class="w-100 suscription" v-if="entrySelected.join_url">
            <a target="_blank" class="btn-blue" :href="entrySelected.join_url" title="Inscribirme al evento">Inscribirme</a>
          </div>
          <!-- File adjunto evento -->
          <div class="list-download" v-if="entrySelected.file && this.$route.params.typeContent == 'evento'">
            <a download :href="entrySelected.file[0].url" class="btn-blue-border" rel="noopener" target="_blank"> Descargar archivo adjunto</a>
          </div>
        </section>
      </section>
    </section>

    <carrousel-2 class="container m-bottom-40-px" :dataContent="$tm('translations.carrouselData.cermi')" />
    <other-links-interest class="links-interest-box container" />
  </section>
</template>

<script lang="js">
import HeroGlobal from "@/components/Global/HeroGlobal";
import OtherLinksInterest from "@/components/Layout/OtherLinksInterest";
import Carrousel2 from "@/components/Global/Carrousel2";
import moment from 'moment'
import { contentStore } from '@/stores/contents'
import EditorJs from "@/components/Global/editorJs.vue"
import rrssShared from "@/components/Global/rrss-shared.vue";
import Markdown from 'vue3-markdown-it';
export default {
  name: 'expositions',
  props: ['id', 'slug'],
  components: {
    OtherLinksInterest,
    HeroGlobal,
    Carrousel2,
    EditorJs,
    rrssShared,
    Markdown
  },
  setup() {
    const store = contentStore();
    return {
      store,
    }

  },
  mounted() {
    switch (this.$route.params.typeContent) {
      case 'noticia':
        if (this.store.news.items[this.yearSelected]) {
          this.entrySelected = this.store.news.items[this.yearSelected][this.store.news.viewPage][this.slug]
        }
        break;
      case 'evento':
        if (this.store.eventsLatest.items[this.yearSelected]) {
          this.entrySelected = this.store.eventsLatest.items[this.yearSelected][this.store.eventsLatest.viewPage][this.slug]
        }
        else if (this.store.events.items[this.year]) {
          this.entrySelected = this.store.events.items[this.year][this.store.events.viewPage][this.slug]
        }
        break;
      case 'multimedia':
        if (this.store.videos.items[this.yearSelected]) {
          this.entrySelected = this.store.videos.items[this.yearSelected][this.store.videos.viewPage][this.slug]
        }
        else if (this.store.images.items[this.yearSelected]) {
          this.entrySelected = this.store.images.items[this.yearSelected][this.store.images.viewPage][this.slug]
        }
        else {
          this.entrySelected = null
        }
        break;
      case 'novedad':
        if (this.store.novelties.items[this.yearSelected]) {
          this.entrySelected = this.store.novelties.items[this.yearSelected][this.store.novelties.viewPage][this.slug]
        }
        break;
      default:
        this.store.loadNews();
    }
    let t = this
    let callEntry = ''
    if (this.entrySelected == null) {
      switch (this.$route.params.typeContent) {
        case 'noticia':
          t.callEntry = this.store.loadNew({
            slug: this.slug
          });
          break;
        case 'evento':
          t.callEntry = this.store.loadEvent({
            slug: this.slug
          });
          break;
        case 'multimedia':
          t.callEntry = this.store.loadMultimediaEntry({
            slug: this.slug
          });
          break;
        case 'novedad':
          t.callEntry = this.store.loadNoveti({
            slug: this.slug
          });
          break;

        default:
          t.callEntry = this.store.loadNew({
            slug: this.slug
          });
      }
      //Resolvemos el promise con un then
      t.callEntry.then(function (result) {
        t.entrySelected = result
        t.changeMetaUrl()


      });
    }
  },
  data() {
    return {
      entrySelected: null,
      year: new Date().getFullYear(),


    }
  },
  methods: {

    changeMetaUrl: function () {
      let title = null
      let description = null
      if (this.entrySelected) {
        title = this.entrySelected.seo_name ? this.entrySelected.seo_name : this.entrySelected.name
        description = this.entrySelected.seo_description ? this.entrySelected.seo_description : this.entrySelected.header

        document.title = title
        document.head.querySelector("[name=description]").content = description
        document.head.querySelector("[name=title]").content = title
        //
        document.head.querySelector("[property='og:url']").content = window.location.href;
        document.head.querySelector("[property='og:title']").content = title;
        document.head.querySelector("[property='og:image']").content = this.entrySelected.image.url;
        document.head.querySelector("[property='og:description']").content = description
        //
        document.head.querySelector("[name='twitter:title']").content = title;
        document.head.querySelector("[name='twitter:description']").content = description;
        document.head.querySelector("[name='twitter:image']").content = this.entrySelected.image.url;
        document.head.querySelector("[name='twitter:text:title").content = title;
      }
    },
    currentDateTime(date) {
      return moment(date).locale('es').format('L')
    },
    currentDateMonth(date) {
      return moment(date).locale('es').format('MMMM')
    },
    currentDateDay(date) {
      return moment(date).locale('es').format('D')
    },
    goToBack() {
      this.$router.back()
    },
    verifyIframe(url) {
      var video_id = '';
      var ampersandPosition = '';
      if (url.indexOf('vimeo.com/') > 0) {
        video_id = url.split('vimeo.com/')[1];
        ampersandPosition = video_id.indexOf('&');
        video_id = 'https://player.vimeo.com/video/' + video_id;

        return video_id
      }
      else if (url.indexOf('v=') > 0) {
        video_id = url.split('v=')[1];
        ampersandPosition = video_id.indexOf('&');
        if (ampersandPosition != -1) {
          video_id = video_id.substring(0, ampersandPosition);
        }
        return 'https://www.youtube.com/embed/' + video_id
      }

      else {
        return
      }

    }
  },
  computed: {
    yearSelected() {
      let year = new Date().getFullYear()
      switch (this.$route.params.typeContent) {
        case 'noticia':
          if (this.store.news.lastFilters.date) {
            year = this.store.news.lastFilters.date
          }
          return year
        case 'evento':
          if (this.store.eventsLatest.lastFilters.date) {
            year = this.store.eventsLatest.lastFilters.date
          }
          return year
        case 'novedad':
          if (this.store.novelties.lastFilters.date) {
            year = this.store.novelties.lastFilters.date
          }
          return year
        case 'multimedia':
          if (this.store.images.lastFilters.date != new Date().getFullYear()) {
            year = this.store.images.lastFilters.date
          }
          else if (this.store.videos.lastFilters.date != new Date().getFullYear()) {
            year = this.store.videos.lastFilters.date
          }
          else {
            year = new Date().getFullYear()
          }
          return year

        default:
          return 'multimedia'
      }

    }
  },
  watch: {
    'entrySelected'() {
      this.changeMetaUrl()

    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/colours.scss";

.page-entry {
  margin: 70px 0 50px 0;
  a {
    word-break: break-all;
  }
  .title-h1 {
    margin-bottom: 10px;
    font-weight: initial;
  }

  .category-location {
    margin-left: 15px;
  }

  .container-img {
    padding: 20px 40px;

    &.-event {
      padding-left: 105px;
    }

    span {
      text-align: center;
      display: inline-block;
      width: 100%;
    }

    .main-img {
      width: 100%;
      max-height: 400px;
      object-fit: contain;
      border-radius: 13px;
      object-position: center center;
      margin-bottom: 10px;
    }
  }

  .container-header-featured {
    background: $blueLight;
    padding: 40px;
    border-radius: 13px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    position: relative;
    margin: 30px 0 20px 0;

    .title-dest-section {
      position: absolute;
      font-size: 135px;
      color: $blueLight;
      font-family: "Frank Ruhl Libre", serif;
      font-weight: 700;
      right: 40px;
      top: -120px;
      margin: 20px 0;
    }

    .main-img {
      width: 250px;
      height: 250px;
      object-fit: cover;
      border-radius: 50%;
      object-position: center center;
      margin-bottom: 10px;
    }

    .container-info {
      width: calc(100% - 250px);
      padding-left: 40px;
      background-image: url(/img/icons/icon-cedilla.svg);
      background-repeat: no-repeat;
      background-position: 40px top;
      background-size: 65px;
      padding-top: 65px;
    }

    &.-interview {
      padding: 0;
      margin-top: 30px;

      .main-img {
        width: 35%;
        height: 250px;
        -o-object-fit: cover;
        object-fit: cover;
        border-radius: 13px;
        -o-object-position: center center;
        object-position: center center;
        margin-bottom: 0px;
      }

      .container-info {
        width: 65%;
        padding-left: 30px;
        padding-right: 30px;
        background: none;

        .name {
          font-size: 30px;
          font-weight: 700;
          line-height: 1.2em;
          color: $blue;
          font-family: "Frank Ruhl Libre", serif;
          margin-bottom: 15px;
        }

        .charge {
          font-size: 24px;
          font-weight: 700;
          line-height: 1.2em;
          color: $blue;
          font-family: "Frank Ruhl Libre", serif;
        }
      }
    }

    &.-reportage {
      padding: 0;
      background: $blue;
      margin-top: 30px;

      .title-dest-section {
        top: -160px;
      }

      .main-img {
        width: 50%;
        height: 350px;
        -o-object-fit: cover;
        object-fit: cover;
        -o-object-position: center center;
        object-position: center center;
        margin-bottom: 0;
        border-radius: 13px;
      }

      .container-info {
        width: 50%;
        padding: 30px;
        background: none;

        .subtitle {
          color: white;
        }
      }
    }
  }

  .video-preview-image {
    width: 100%;
    max-width: 800px;
    height: 500px;
    margin: 0 auto;
    padding: 40px 0;

    iframe {
      width: 100%;
      max-width: 800px;
      height: 100%;
      border-radius: 13px;
    }
  }

  .suscription {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
  }
}

.btn-back {
  color: $blue;
  background-image: url(/icons/arrow-left-orange.svg);
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 18px;
  padding-left: 25px;
  min-height: 20px;
  font-size: 20px;
  margin-bottom: 20px;
  display: block;
  text-decoration: none;
  cursor: pointer;
}

.url-download {
  background: rgba(0, 105, 180, 0.08);
  text-decoration: none;
  width: 100%;
  display: inline-block;
  padding: 20px 20px 20px 46px;
  cursor: pointer;
  background-image: url(/img/icons/file_download.svg);
  background-repeat: no-repeat;
  background-position: 10px 12px;
  background-size: 30px;
  margin: 40px 0;
}

.head-content {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;

  .content-cont {
    width: calc(100% - 105px);
    flex-grow: 1;
  }

  .box-date {
    margin-right: 15px;
    display: flex;
    flex-direction: column;
    background: $red;
    color: white;
    padding: 10px;
    border-radius: 7px;
    align-items: center;
    width: 90px;
    min-width: 90px;

    span {
      color: white;
      text-align: center;
      font-family: "Frank Ruhl Libre", serif;
      line-height: 0.8em;

      &.number {
        font-weight: 700;
        font-size: 65px;
      }

      &.month {
        font-size: 18px;
      }
    }
  }
}

.container-head-data {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;

  .shared-header {
    width: 400px;

    .rrss-shared {
      margin-top: 0;
    }
  }

  .info-data {
    width: calc(100% - 400px);
  }
}

p,
p b {
  font-size: 17px;
}

.subtitle {
  font-size: 20px;
}

.newsletter-detail.cont-evento {
  padding-bottom: 50px;

  .newsletter-body,
  .source-p {
    padding-left: 107px;
    padding-right: 25px;
  }
}

@media screen and (max-width: 845px) {
  .container-head-data .info-data {
    width: 100%;
  }

  .container-head-data .shared-header {
    width: 100%;
  }

  .container-head-data .shared-header .rrss-shared {
    margin-top: 0;
    padding-left: 0 !important;
    margin-bottom: 20px;
  }

  .page-entry .container-img.-event {
    padding: 20px 0;
  }

  .page-entry .container-img .main-img {
    height: auto;
  }

  .page-entry .container-header-featured .title-dest-section {
    font-size: 80px;
    top: -97px;
  }

  .page-entry .container-header-featured.-reportage .main-img {
    width: 100%;
    height: auto;
    max-width: 100%;
  }

  .page-entry .container-header-featured.-reportage .container-info {
    width: 100%;
    padding: 20px;
  }

  .newsletter-detail.cont-evento {
    .newsletter-body,
    .source-p {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
}

@media screen and (max-width: 750px) {
  .page-entry .container-header-featured .title-dest-section {
    font-size: 55px;
    top: -74px;
    left: 0;
  }

  .page-entry .container-header-featured.-reportage .title-dest-section {
    top: -74px;
  }

  .head-content .content-cont {
    width: 100%;
    margin-top: 10px;
  }

  .head-content .box-date {
    margin-right: 0;
  }

  .head-content .box-date span.number {
    font-weight: 700;
    font-size: 40px;
  }

  .page-entry .container-header-featured .container-info {
    width: 100%;
    padding-left: 0;
    background-position: 0 top;
    background-size: 50px;
    padding-top: 48px;
  }

  .page-entry .container-header-featured .main-img {
    width: 100%;
    height: 250px;
    max-width: 250px;
    margin: 0 auto;
  }

  .page-entry .container-header-featured.-interview .main-img {
    width: 100%;
    height: auto;
    max-width: 100%;
    margin: 0 auto;
  }

  .page-entry .container-header-featured.-interview .container-info {
    width: 100%;
    padding: 20px;
  }

  .page-entry .container-header-featured.-interview .container-info .name {
    font-size: 25px;
    margin-bottom: 10px;
  }

  .page-entry .container-header-featured {
    padding: 20px;
  }
}
</style>
